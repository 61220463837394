import Network from '@/services/network'

export default function () {
    const network = new Network;

    const fetchStaffRegister = (query) => {
        return network.api('get', '/sdm/get-staff-register' + query);
    }

    return {
        fetchStaffRegister,
    }
}